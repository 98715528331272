.leaflet-container {
  height: 60vh;
  width: 100%;
}

.map.card {}

.map.card .card-body {
  padding: 1.25rem 0;
  padding-bottom: 0;
}

.map.card .card-footer p {
  margin: 0;
  font-size: 0.8em;
  color: #777;
}

.map.card .controls {
  flex-direction: column;
  margin-bottom: 0;
}

.map.card .controls .line {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 1rem;
}

.map.card .controls .line .form-group {
  padding: 0;
}

.map.card .controls .line .btn, .map.card .controls .line .wafoformautocomplete, .map.card .controls .line .googleAutocomplete {
  margin-right: .5rem;
}

.map.card .controls .line .btn:last-child {
  margin-right: 0;
}

.map.card .controls .googleAutocomplete {
  flex: 2;
}

.map.card .controls .wafoformautocomplete {
  flex: 1;
}

.map.card .controls .wafoformautocomplete .results {
  z-index: 100000;
  top: 40px;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.map.card .controls .form-group {
  margin-bottom: 0;
}

.customMapMarker {
  height: 65px !important;
  width: 45px !important;
}

.customMapMarker > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}