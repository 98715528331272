.shy-header {
  font-weight: bold;
  margin-bottom: 0;
  color: #ccc;
  font-size: 1.1em;
}

.monitor .filtros .wafoformautocomplete .results {
  z-index: 100000;
  top: 40px;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.monitor .filtros .botones {
  text-align: right;
}

.monitor .tabla-monitor .badge {
  width: 125px;
}

.monitor .mapa-monitor .card,
.monitor .tabla-monitor .card {
  border: 1px solid #395FC2;
}

.monitor .mapa-monitor .card>.card-header,
.monitor .tabla-monitor .card>.card-header {
  background-color: #395FC2;
  color: #efefef;
  font-weight: bold;
}