.modal-monitor .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-monitor .card {
  max-width: 480px;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.modal-monitor .card-header {
  display: flex;
  justify-content: space-between;
  color: #f5f5f5;
  border: none;
}

.modal-monitor .card-header h5 {
  margin-right: 1.5rem;
  font-weight: bold;
}

.modal-monitor .card-header .close {
  color: #fff;
  opacity: 0.9;
}

.modal-monitor .card-body.detalles .dato {
  margin-bottom: .25rem;
}

.modal-monitor .card-body.detalles .dato .badge {
  font-size: 0.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.modal-monitor .card-body .separador {
  background-color: #ccc;
  height: 1px;
  margin: .5rem 0;
}

.modal-monitor .card-body.detalles .dato .status {

}

.modal-monitor .card-body.detalles .dato .status .badge {
  width: auto;
  margin-right: .5rem;
}

.modal-monitor .card-body.detalles .dato .status .badge:last-child {
  margin-right: 0;
}

.modal-monitor .animate-in {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadein .5s;
  /* Firefox < 16 */
  -moz-animation: fadein .5s;
  /* Internet Explorer */
  -ms-animation: fadein .5s;
  /* Opera < 12.1 */
  -o-animation: fadein .5s;
  animation: fadein .5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}