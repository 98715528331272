.alertas-sl {
  position: absolute;
  z-index: 10000;
  top: 1.5rem;
  right: 1.5rem;
  margin-bottom: 0;
  padding: 0;
}

.alert-sl {
  width: 50vw;
  /* Shadow */
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

.alert-sl.animate-in {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadein .5s;
  /* Firefox < 16 */
  -moz-animation: fadein .5s;
  /* Internet Explorer */
  -ms-animation: fadein .5s;
  /* Opera < 12.1 */
  -o-animation: fadein .5s;
  animation: fadein .5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.alert-sl.animate-out {
  /* Safari, Chrome and Opera > 12.1 */
  -webkit-animation: fadeout .5s;
  /* Firefox < 16 */
  -moz-animation: fadeout .5s;
  /* Internet Explorer */
  -ms-animation: fadeout .5s;
  /* Opera < 12.1 */
  -o-animation: fadeout .5s;
  animation: fadeout .5s;
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}