.leaflet-container {
  height: 60vh;
  width: 100%;
}

.map.card {}

.map.card .card-body {
  padding: 1.25rem 0;
  padding-bottom: 0;
}

.map.card .card-footer p {
  margin: 0;
  font-size: 0.8em;
  color: #777;
}

.map.card .controls {
  flex-direction: column;
  margin-bottom: 0;
}

.map.card .controls .line {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0 1.25rem;
  margin-bottom: 1rem;
}

.mapa-locations .controls .line label {
  font-weight: bold;
  color: #777;
  margin-bottom: 0;
}

.map.card .controls .line .form-group {
  padding: 0;
}

.mapa-locations .map.card .controls .line .wafoformautocomplete .results {
  top: 60px;
}

.map.card .controls .line .btn, .map.card .controls .line .wafo-input, .map.card .controls .line .sl-horariopicker {
  margin-right: .5rem;
}

.map.card .controls .line .btn:last-child {
  margin-right: 0;
}

.map.card .controls .wafoformautocomplete {
  flex: 3 !important;
}

.map.card .controls .wafo-input {
  flex: 2
}

.map.card .controls .wafo-input, .map.card .controls .sl-horariopicker {
  flex: 1;
}

.map.card .controls .wafoformautocomplete .results {
  z-index: 100000;
  top: 40px;
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.map.card .controls .form-group {
  margin-bottom: 0;
}