.loading-bar {
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.sl-loadingBar {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.sl-loadingBar .innerBar {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  to {
    left: 100%;
  }
}