.historial .mapa-locations .card,
.historial .tabla-monitor .card,
.historial .tabla-historial .card,
.historial .tabla-historial .card {
  border: 1px solid #395fc2;
}

.historial .mapa-locations .card > .card-header,
.historial .tabla-monitor .card > .card-header,
.historial .tabla-historial .card > .card-header,
.historial .tabla-historial .card > .card-header {
  background-color: #395fc2;
  color: #efefef;
  font-weight: bold;
}

.historial .tabla-historial .card > .card-body .distance {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.historial .tabla-historial .card > .card-body .distance > h5 {
  margin-bottom: 0;
  margin-right: .5rem;
  font-weight: bold;
}