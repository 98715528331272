.sidebar {
  min-width: 250px;
  max-width: 250px;
  height: 100vh;
  background-color: #313E61;
  color: #efefef;
  /* Transitions */
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-links {
  list-style: none;
  padding: 1rem 0;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.sidebar-links li {
  cursor: pointer;
}

.sidebar-links li a.active {
  background: #395FC2;
  color: #efefef;
}

.sidebar-links a {
  display: block;
  width: 100%;
  padding: 10px 20px;
  color: #efefef;
  text-decoration: none;
  font-size: 1.1em;
  /* Transitions */
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar-links a:hover {
  color: #efefef;
  background: #395FC2;
}

.sidebar-links li.active a:hover {
  background: #395FC2;
  color: #efefef;
}

.sidebar-links a .svg-inline--fa {
  margin-right: 5px;
  width: 25px;
}

.sidebar-links a .fas {
    width: 25px;
}

.sidebar-links li .notification-badge {
  margin-left: .5rem;
  font-weight: bold;
  color: #000;
}

.btn-toggle-mobile {
  display: none;
}

.sidebar .header {
  padding: 1rem;
  padding-top: 1.25rem;
  text-align: center
}

.sidebar .header .svg-inline--fa {
  font-size: 6em;
}

.sidebar .header p {
  margin-bottom: 0;
}

.sidebar-links-extra {
  border: none;
  padding: 0;
}

/** ******************************************** */
/** Collapsed */
/** ******************************************** */

.sidebar.collapsed {
  max-width: 80px;
  min-width: 80px;
}

.sidebar.collapsed .header {
  display: none;
}

.sidebar.collapsed .sidebar-links a {
  padding: 10px;
  font-size: 0.8em;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
}

.sidebar.collapsed .sidebar-links a .svg-inline--fa {
  margin: 0;
  width: 100%;
  font-size: 1.5em;
}

.sidebar.collapsed .sidebar-footer .texto-escondido {
  display: none;
}

.sidebar.collapsed .sidebar-links a span {
  display: none;
}

.sidebar.collapsed .sidebar-links li .notification-badge {
  display: block;
  margin: 0;
  font-size: 1em;
}

/** ******************************************** */
/** Movil */
/** ******************************************** */

@media (max-width: 768px) {
  .sidebar.collapsed {
    max-width: 0px;
    min-width: 0px;
  }
  .sidebar.collapsed .sidebar-links {
    display: none;
  }
}